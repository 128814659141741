import React from "react";

function IconLink(props) {
  const { link, title, icon } = props;
  const BenIcon = 'fa fa-ban'
  return (
    <>
      {link !== "/" ? (
        <a href={link} target={"_blank"} rel="noopener noreferrer">
          <i className={icon} /> {title}
        </a>
      ) : (
          <><i className={BenIcon} /><a style={{'textDecoration':'line-through'}}> {title}</a></>
      )}
    </>
  );
}

export default IconLink;
