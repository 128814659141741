import self from "../img/self.png";
import mock1 from "../img/mock1.png";
import mock2 from "../img/mock2.png";
import mock3 from "../img/mock3.png";
import { Star } from "@mui/icons-material";

/* Hi there! Thanks for checking out my portfolio template. Be sure to read the comments to get a better understanding of
how to make this template work best for you! */

// export let colors = ["rgb(0,255,164)", "rgb(166,104,255)"];
export let colors = ["rgb(255 3 3)", "rgb(88 0 212)"];
/*
I highly recommend using a gradient generator like https://gradientgenerator.paytonpierce.dev/ to generate a pair of colors that you like.
These colors will be used to style your name on the homepage, the background of your picture, and some other accents throughout
the site.
 */

/*
So let's get started! Some of the info below is pretty self-explanatory, like 'firstName' and 'bio'. I'll try to explain anything
that might not be obvious right off the bat :) I recommend looking at the template example live using "npm start" to get an idea
of what each of the values mean.
 */

export const info = {
  firstName: "Hardik",
  lastName: "Chavda",
  initials: "Hardik Chavda", // the example uses first and last, but feel free to use three or more if you like.
  position: "a Full Stack Developer",
  selfPortrait: self, // don't change this unless you want to name your self-portrait in the "img" folder something else!
  gradient: `-webkit-linear-gradient(135deg, ${colors})`, // don't change this either
  baseColor: colors[0],
  terminalClolor: "rgb(33 246 0)",
  subterminalColor: "rgb(0 255 239)",
  miniBio: [
    // these are just some "tidbits" about yourself. You can look at mine https://paytonjewell.github.io/#/ for an example if you'd like

    {
      emoji: "🎓",
      text: "Student at Marwadi University",
    },
    {
      emoji: "🌎",
      text: "Based in the India",
    },
    {
      emoji: "📧",
      text: "chavdah80@gmail.com",
      text2: "chavdah80@gmail.com",
    },
    {
      emoji: "📞",
      text: "+91 81539 10949",
    },
  ],
  socials: [
    {
      link: "https://www.instagram.com/mrhard1k/",
      icon: "fa fa-instagram",
      label: "instagram",
    },
    {
      link: "https://github.com/MrHadik",
      icon: "fa fa-github",
      label: "github",
    },
    {
      link: "https://www.linkedin.com/in/mrhard1k",
      icon: "fa fa-linkedin",
      label: "linkedin",
    },
    {
      link: "https://twitter.com/Mr_Hadik",
      icon: "fa fa-twitter",
      label: "twitter",
    },
    {
      link: "https://www.facebook.com/Mr.Hadik",
      icon: "fa fa-facebook",
      label: "facebook",
    },
    // Feel free to remove any of these that you don't have. I'll write an FAQ on how to add new ones later, let me know if you have a request for an icon!
    // Just change the links so that they lead to your social profiles.
  ],
  bio: "Hello! I’m Hardik. I am Pursuing a B.Tech in information technology from Marwadi University",
  education: [
    {
      label: "Marwadi University - Rajkot",
      emoji: "B.Tech in I.T.",
    },
    {
      label:
        "Shri Labhubhai Trivedi Institute Of Engineering And technology College - Rajkot",
      emoji: "Diploma in Civil",
    },
    {
      label: "Lothika ITI - Rajkot",
      emoji: "ITI in C.O.P.A.",
    },
    {
      label: "Ganthi Gyan Mandir - Rajkot",
      emoji: "SSC",
    },
  ],
  certificates: [
    {
      emoji: "AWS Academy",
      label: " AWS Cloud Foundations",
      link: "https://www.credly.com/badges/71da8316-ce8f-4080-ab7a-3f326b980af0/linked_in_profile",
    },
    {
      emoji: "Cognitive Class (IBM)",
      label: "Python for Data Science",
      link: "https://courses.cognitiveclass.ai/certificates/b988724599974d5aa1bc7a2173f84670",
    },
    {
      emoji: "TRYHACKME",
      label: "INTRODUCTION TO CYBER SECURITY",
      link: "https://tryhackme-certificates.s3-eu-west-1.amazonaws.com/THM-ALDOZFIPOI.png",
    },
    {
      emoji: "COURSERA",
      label: "HTML, CSS, AND JAVASCRIPT FOR WEB DEVELOPERS",
      link: "https://www.coursera.org/account/accomplishments/verify/CB6YTKRF8NC5",
    },
    {
      emoji: "ORACLE",
      label: "DATABASE PROGRAMMING WITH SQL",
      link: "https://drive.google.com/file/d/1eIcDHzKDIwnGvJV35LN6AhwZ0y0JCs-2/view",
    },
    {
      emoji: "CISCO NETWORKING",
      label: "CCNA ROUTING AND SWITCHING: INTRODUCTION TO NETWORKS",
      link: "https://drive.google.com/file/d/1G4MjRcTv0UPWrNvOkQbvNDg_ylKh3M06/view",
    },
    {
      emoji: "CYBRARY",
      label: "INTRODUCTION TO IT & CYBERSECURITY",
      link: "https://app.cybrary.it/courses/api/certificate/CC-b78a9030-d577-4408-bd61-8cd46bae5913/view",
    },
    {
      emoji: "HACKERRANK",
      label: "SQL (BASIC)",
      link: "https://www.hackerrank.com/certificates/66053fbc5548",
    },
  ],
  skills: {
    proficientWith: [
      "javascript",
      "Typescript",
      "react",
      "git",
      "github",
      "bootstrap",
      "html5",
      "AWS",
      "Linux",
      "C++",
      "C",
      "Python",
      "Next.js",
      "Laravel",
    ],
    exposedTo: ["nodejs"],
  },
  hobbies: [
    {
      label: "Hacking",
      emoji: "☣",
    },
    {
      label: "movies",
      emoji: "🎬",
    },
    {
      label: "Technology",
      emoji: "📡",
    },
    // Same as above, change the emojis to match / relate to your hobbies or interests.
    // You can also remove the emojis if you'd like, I just think they look cute :P
  ],
  portfolio: [
    // This is where your portfolio projects will be detailed
    {
      title: "Nimit (Medicine Management for NGO)",
      live: "https://nimit-demo.vercel.app/", //this should be a link to the live version of your project, think github pages, netlify, heroku, etc. Or your own domain, if you have it.
      source: "https://github.com/MrHadik/Nimit", // this should be a link to the **repository** of the project, where the code is hosted.
      image: mock3,
    },
    {
      title: "SudoMovies",
      live: "https://sudomovies.mrhard1k.com/", //this should be a link to the live version of your project, think github pages, netlify, heroku, etc. Or your own domain, if you have it.
      source: "https://github.com/MrHadik/SudoMovies", // this should be a link to the **repository** of the project, where the code is hosted.
      image: mock1,
    },
    {
      title: "YesNews",
      live: "/",
      source: "https://github.com/MrHadik/YesNews",
      image: mock2,
    },
  ],
  experience: [
    // This is where your portfolio projects will be detailed
    {
      jobTitle: "SudoMovies",
      companyName: "https://sudomovies.mrhard1k.com/", //this should be a link to the live version of your project, think github pages, netlify, heroku, etc. Or your own domain, if you have it.
      companyLogo: "https://github.com/MrHadik/SudoMovies", // this should be a link to the **repository** of the project, where the code is hosted.
      companyURL: "https://github.com/MrHadik/SudoMovies", // this should be a link to the **repository** of the project, where the code is hosted.
      startedAt: "",
      endedAt: "",
      learningPoint: ["this is working", "good fpor trhis "],
    },
    {
      jobTitle: "SudoMovies",
      companyName: "https://sudomovies.mrhard1k.com/", //this should be a link to the live version of your project, think github pages, netlify, heroku, etc. Or your own domain, if you have it.
      companyLogo: "https://github.com/MrHadik/SudoMovies", // this should be a link to the **repository** of the project, where the code is hosted.
      companyURL: "https://github.com/MrHadik/SudoMovies", // this should be a link to the **repository** of the project, where the code is hosted.
      startedAt: "",
      endedAt: "",
      learningPoint: ["this is working", "good fpor trhis "],
    },
  ],
};
