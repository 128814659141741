import React from 'react';
import Style from './About.module.scss';
import Terminal from "./Terminal";
import {Box} from "@mui/material";
import {info} from "../../info/Info";
import SocialIcon from "../home/SocialIcon";


export default function About() {
    const firstName = info.firstName;

    function aboutMeText() {
        return <>
            <p><span style={{color: info.terminalClolor}}>{firstName}{info.lastName} $</span> cat
                about{firstName} </p>
            <p><span style={{color: info.terminalClolor}}>about{firstName} <span
                className={Style.green}>(main)</span> $ </span>
                {info.bio}
            </p>
        </>;
    }

    function skillsText() {
        return <>
            <p><span style={{color: info.terminalClolor}}>{firstName}{info.lastName} $</span> cd skills/tools
            </p>
            <p><span style={{color: info.terminalClolor}}>skills/tools <span
                className={Style.green}>(main)</span> $</span> ls</p>
            <p style={{color: info.subterminalColor}}> Proficient With</p>
            <ul className={Style.skills}>
                {info.skills.proficientWith.map((proficiency, index) => <li key={index}>{proficiency}</li>)}
            </ul>
            <p style={{color: info.subterminalColor}}> Exposed To</p>
            <ul className={Style.skills}>
                {info.skills.exposedTo.map((skill, index) => <li key={index}>{skill}</li>)}
            </ul>
        </>;
    }

    function miscText() {
        return <>
            <p><span style={{color: info.terminalClolor}}>{firstName}{info.lastName} $</span> cd
                hobbies/interests</p>
            <p><span style={{color: info.terminalClolor}}>hobbies/interests <span
                className={Style.green}>(main)</span> $</span> ls</p>
            <ul>
                {info.hobbies.map((hobby, index) => (
                    <li key={index}><Box component={'span'} mr={'1rem'}>{hobby.emoji}</Box>{hobby.label}</li>
                ))}
            </ul>
        </>;
    }


    function education() {
        return <>
            <p><span style={{color: info.terminalClolor}}>{firstName}{info.lastName} $</span> cd education</p>
            <p><span style={{color: info.terminalClolor}}>education <span
                className={Style.green}>(main)</span> $</span> ls</p>
            <ul>
                {info.education.map((hobby, index) => (
                    <li key={index}><Box component={'span'} mr={'1rem'}><span style={{color: info.subterminalColor}}>{hobby.emoji}</span> : {hobby.label}</Box></li>
                ))}
            </ul>
        </>;
    }

    function certificates() {
        return <>
            <p><span style={{color: info.terminalClolor}}>{firstName}{info.lastName} $</span> cd certificates</p>
            <p><span style={{color: info.terminalClolor}}>certificates <span
                className={Style.green}>(main)</span> $</span> ls</p>
            <ul>
                {info.certificates.map((hobby, index) => (
                    <li key={index}><Box component={'span'} mr={'1rem'}><span style={{color: info.subterminalColor}}>{hobby.emoji}</span> : {hobby.label} <a href={hobby.link} rel="noreferrer" target='_blank'><SocialIcon  icon='fa fa-link'/></a></Box></li>
                ))}
            </ul>
        </>;
    }

    return (
        <Box display={'flex'} flexDirection={'column'} alignItems={'center'} mt={'3rem'}>
            <Terminal text={aboutMeText()}/>
            <Terminal text={education()}/>
            <Terminal text={certificates()}/>
            <Terminal text={skillsText()}/>
            <Terminal text={miscText()}/>
        </Box>
    )
}